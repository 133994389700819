import React from "react"
import Header from "./header"
import Footer from "./footer"
import GlobalStyles from "./Global"

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <GlobalStyles />

      <main>{children}</main>

      <Footer />
    </>
  )
}

export default Layout
