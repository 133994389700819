import styled from "styled-components"

export const MainTitle = styled.h2`
  color: white;
  font-size: 4rem;
  text-transform: uppercase;
  text-shadow: #000000 0 6px 12px;
  padding: 3rem 0 1rem 0;
`

export const LightBlueTitle = styled.h2`
  color: #1b75bc;
  font-size: ${props => props.size || "4rem"};
  text-transform: uppercase;
`

export const MainBlueTitle = styled.h2`
  color: #004171;
  font-size: ${props => props.size || "4rem"};
  text-transform: uppercase;
`

export const GreenTitle = styled.h3`
  color: #8ec641;
  font-size: ${props => props.size || "3rem"};
  text-transform: uppercase;

  & a {
    color: #8ec641;
    text-decoration: none;
  }
`

export const LightGreenTitle = styled.h3`
  color: #a9ff00;
  font-size: ${props => props.size || "3rem"};
  text-transform: uppercase;

  & a {
    color: #a9ff00;
    text-decoration: none;
  }
`
