import React, { useState } from "react"
import styled from "styled-components"

import { Link } from "gatsby"
import { FaAlignLeft } from "react-icons/fa"
import Container from "./utilities/wrapper"
import Mainlog from "../images/assets/optima-graphix.svg"

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;

  .active {
    color: #8aba2b;
  }
  @media (max-width: 1135px) {
    flex-direction: column;
  }
`

const NavBrand = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 180px;
  }
`

const BurgerMenu = styled.div`
  position: absolute;
  top: 55px;
  right: 40px;
  cursor: pointer;

  @media (min-width: 1136px) {
    display: none;
  }
`
const NavLinks = styled.ul`
  height: auto;
  display: flex;
  overflow: hidden;
  a {
    color: #004171;
    margin: 0 1rem;
    font-size: 1.1rem;
    text-decoration: none;
    transition: all 0.3s;
    display: block;
  }
  a:hover {
    color: #8aba2b;
  }

  @media (max-width: 1135px) {
    height: ${props => (props.toggle ? "400px" : "0px")};
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.5s;
    width: 100%;
    background: #f0f0f0;

    a {
      padding: 1rem 1.25rem;
    }
  }
`

const Header = () => {
  const [toggleBurger, setToggleBurger] = useState(false)

  const toggleMenu = () => {
    setToggleBurger(!toggleBurger)
  }

  return (
    <header>
      <nav>
        <Container>
          <NavContainer>
            <NavBrand>
              <Link to="/">
                <Mainlog width="180px" />
              </Link>
              <BurgerMenu onClick={toggleMenu}>
                <FaAlignLeft color="#004171" size="25px" />
              </BurgerMenu>
            </NavBrand>
            <NavLinks toggle={toggleBurger}>
              <Link to="/" activeClassName="active">
                Home
              </Link>
              <a
                href="https://optimagraphix.dcpromosite.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Promotional Items
              </a>
              <a
                href="https://companycasuals.com/optimagraphix/start.jsp"
                target="_blank"
                rel="noopener noreferrer"
              >
                Store
              </a>

              <Link to="services" activeClassName="active">
                Services
              </Link>
              <Link to="portfolio" activeClassName="active">
                Portfolio
              </Link>
              <Link to="about-us" activeClassName="active">
                About Us
              </Link>

              <Link to="contact" activeClassName="active">
                Contact Us
              </Link>
            </NavLinks>
          </NavContainer>
        </Container>
      </nav>
    </header>
  )
}

export default Header
