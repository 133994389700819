import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: all-round-gothic, sans-serif;
  font-weight: 700;
  font-style: normal;
}

ul {
  list-style: none;
}

nav {
  font-family: all-round-gothic, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@media only screen and (max-width: 480px) {
  html {
    font-size: 80%;
  }
}


`

export default GlobalStyles
