import React from "react"
import styled from "styled-components"
import Veteran from "../images/Veteran.png"
import { FaFacebookF, FaTwitter } from "react-icons/fa"
import Container from "./utilities/wrapper"
import MapImg from "../images/location-map.jpg"

const StyledFooterElement = styled.div`
  background-color: #004171;
`

const StyledFooter = styled.div`
  background-color: #004171;
  color: white;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  align-items: flex-start;
  padding: 2rem 0;

  a {
    text-decoration: none;
    color: white;
  }

  h4 {
    color: #8aba2b;
    font-size: 1.5rem;
  }

  .vet-footer {
    padding: 1rem 0;
    font-size: 0.75rem;
  }

  .vet-footer img {
    width: 48px;
  }

  .icons-wrapper {
    display: flex;
    justify-content: center;
  }

  .social-icons h4 {
    padding: 0.5rem 0;
  }

  .social-icons a {
    text-align: center;
    margin: 10px;
  }

  .info-hours h4 {
    padding: 0.5rem 0;
  }

  .map-section h4 {
    padding: 0.5rem 0;
  }

  .map-section p {
    padding: 0.5rem 0;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 4.5rem;

    .icons-wrapper {
      justify-content: flex-start;
    }
  }
`

const CopyDate = styled.div`
  background-color: #004171;
  max-width: 1280px;
  padding: 2rem 0;
  margin: 0 auto;
  color: white;
  text-align: center;
`

const footer = () => {
  return (
    <StyledFooterElement>
      <Container>
        <StyledFooter>
          <div className="info-hours">
            <h4>Call Us</h4>
            <a href="tel:+19153077231">(915) 307-7231</a>
            <h4>Hours</h4>
            <p>M-F 9 a.m. to 5 p.m.</p>
            <p className="vet-footer">
              <span>
                <img src={Veteran} alt="solider saluting" />
              </span>
              Veteran Owned and Operated
            </p>
          </div>
          <div className="map-section">
          
            <p>
           
            </p>
      
          </div>
          <div className="social-icons">
            <h4>Connect with us</h4>
            <div className="icons-wrapper">
              <a
                href="https://twitter.com/optimagraphix/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter size="30px" />
              </a>
              <a
                href="https://www.facebook.com/optimagraphix/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF color="white" size="30px" />
              </a>
            </div>
          </div>
        </StyledFooter>
      </Container>

      <CopyDate>© {new Date().getFullYear()} - Optima Graphix</CopyDate>
    </StyledFooterElement>
  )
}

export default footer
